import { colors } from "../styles";

export const dark = {
  name: 'dark',
  background: '#111',
  foreground: '#fff',
  backgroundAccent: colors.darkGray,
  headingColor: colors.white,
  bodyColor: colors.lightGray,
  linkColor: colors.pri,
}