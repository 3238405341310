// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-template-js": () => import("./../src/templates/blog/post-template.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-blog-category-template-js": () => import("./../src/templates/blog/category-template.js" /* webpackChunkName: "component---src-templates-blog-category-template-js" */),
  "component---src-templates-blog-tag-template-js": () => import("./../src/templates/blog/tag-template.js" /* webpackChunkName: "component---src-templates-blog-tag-template-js" */),
  "component---src-templates-blog-author-template-js": () => import("./../src/templates/blog/author-template.js" /* webpackChunkName: "component---src-templates-blog-author-template-js" */),
  "component---src-templates-feature-page-template-feature-page-template-js": () => import("./../src/templates/feature-page-template/feature-page-template.js" /* webpackChunkName: "component---src-templates-feature-page-template-feature-page-template-js" */),
  "component---src-templates-blog-a-2-i-resource-template-js": () => import("./../src/templates/blog/a2i-resource-template.js" /* webpackChunkName: "component---src-templates-blog-a-2-i-resource-template-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-2-i-world-js": () => import("./../src/pages/a2i-world.js" /* webpackChunkName: "component---src-pages-a-2-i-world-js" */),
  "component---src-pages-a-2-i-world-all-js": () => import("./../src/pages/a2i-world/all.js" /* webpackChunkName: "component---src-pages-a-2-i-world-all-js" */),
  "component---src-pages-a-2-i-world-resources-js": () => import("./../src/pages/a2i-world/resources.js" /* webpackChunkName: "component---src-pages-a-2-i-world-resources-js" */),
  "component---src-pages-a-2-i-world-submit-js": () => import("./../src/pages/a2i-world/submit.js" /* webpackChunkName: "component---src-pages-a-2-i-world-submit-js" */),
  "component---src-pages-a-2-i-world-top-js": () => import("./../src/pages/a2i-world/top.js" /* webpackChunkName: "component---src-pages-a-2-i-world-top-js" */),
  "component---src-pages-a-2-i-js": () => import("./../src/pages/a2i.js" /* webpackChunkName: "component---src-pages-a-2-i-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-campaigns-womens-day-js": () => import("./../src/pages/campaigns/womens-day.js" /* webpackChunkName: "component---src-pages-campaigns-womens-day-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-js": () => import("./../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-help-js": () => import("./../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-amp-js": () => import("./../src/pages/index.amp.js" /* webpackChunkName: "component---src-pages-index-amp-js" */),
  "component---src-pages-new-features-js": () => import("./../src/pages/new-features.js" /* webpackChunkName: "component---src-pages-new-features-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-tool-seo-report-js": () => import("./../src/pages/tool/seo-report.js" /* webpackChunkName: "component---src-pages-tool-seo-report-js" */)
}

