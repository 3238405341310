import { colors } from "../styles";

export const light = {
  name: 'light',
  background: '#fff',
  foreground: '#000',
  backgroundAccent: '#dfdfdf',
  headingColor: colors.black,
  bodyColor: colors.darkGray,
  linkColor: colors.priDark
}