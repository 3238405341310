export const colors = {
  // pri: '#8256EF',
  // sec: '#F2C94C',
  // ter: '#FFC97D',
  // priDark: '#4942D8',
  // secDark: '#d7ad0c',
  // terDark: '#FF6820',
  pri: '#1BB953',
  sec: '#8256EF',
  ter: '#F40052',
  priDark: '#20DF64',
  secDark: '#3C25EC',
  terDark: '#9B000C',
  priGlow: 'rgba(32, 223, 100, 0.25)',
  secGlow: 'rgba(155, 81, 224, 0.25)',
  priGradient: 'linear-gradient(180deg, #1BB953 0%, #20DF64 100%)',
  secGradient: 'linear-gradient(180deg, #4942D8 0%, #8256EF 100%)',
  terGradient: 'linear-gradient(160deg, #9B000C 0%, #F40052 100%)',
  blackGradient: 'linear-gradient(90deg, #000 0%, #0a0a0a 70%, #111 100%)',
  white: '#fff',
  lightGray: '#9E9E9E',
  gray: '#616161',
  darkGray: '#212121',
  black: '#000'
}

export const fonts = {
  pri: `'Montserrat', serif`,
  sec: `'Rubik', sans-serif`,
}

export const dimensions = {
  paraWidth: '650px',
  siteWidth: '1100px',
  tablet: '768px',
  bannerHeight: '42px'
}
