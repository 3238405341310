const React = require("react")
const { GlobalProvider } = require("./src/context/global-context")

exports.wrapRootElement = ({ element }) => {
  return <GlobalProvider>{element}</GlobalProvider>
}

exports.onInitialClientRender = () => {
  // Determine Landing Page
  if(window !== undefined) {
    sessionStorage.setItem("initialUrl", window.location.href)
  } else {
    sessionStorage.setItem("initialUrl", 'https://leadmirror.com')
  }
}
