import React, { useState } from "react"
import { useReducer } from "react"
import { ThemeProvider } from "styled-components"
import { dark } from "../utils/styles/themes/dark"
import { light } from "../utils/styles/themes/light"

// Context Setup
export const GlobalContext = React.createContext(null)

// Init State Setup
const initialState = {
  activeTheme: 'dark',
}

// Actions setup
const CHANGE_THEME = "CHANGE_THEME"

// Reducer Setup
function reducer(state, action) {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        activeTheme: action.payload,
      }
    default:
      return state
  }
}

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <ThemeProvider theme={state.activeTheme === 'dark' ? dark : light}>{children}</ThemeProvider>
    </GlobalContext.Provider>
  )
}
